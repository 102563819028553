html {
  box-sizing: border-box;
  font-size: 16px;
  line-height: 1.25;
}

*, :before, :after {
  box-sizing: inherit;
}

body, h1 {
  margin: 0;
  padding: 0;
  font-weight: normal;
}

img {
  max-width: 100%;
  height: auto;
}

body {
  color: #313f47;
  text-align: center;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  font-weight: 200;
}

.container {
  height: 100vh;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.card {
  width: 100%;
  height: 100vh;
  background-color: #fffffff2;
  border-radius: 1%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  display: flex;
  position: relative;
  bottom: 30px;
  overflow-y: hidden;
}

.avatar {
  align-items: center;
  display: flex;
}

.avatar img {
  box-sizing: content-box;
  width: 140px;
  height: 140px;
  border-radius: 50%;
  padding: 0 20px;
}

.avatar-line {
  width: 100px;
  border-top: 1px solid #c8cccf;
  flex-grow: 1;
}

.links {
  justify-content: center;
  align-items: center;
  display: flex;
}

.link-icon {
  height: 22px;
  width: 22px;
  color: #c8cccf;
  box-sizing: unset;
  border: 2px solid #c8cccf;
  border-radius: 100%;
  margin: 0 10px;
  padding: 10px;
  transition: all .1s ease-in;
  display: inline-block;
}

.link-icon:hover {
  color: #ff6ad5;
  border-color: #ff6ad5;
}

@media only screen and (min-width: 450px) {
  .container {
    background: #c774e8;
    background: -webkit-linear-gradient(to bottom, #c774e8, #94d0ff);
    background: linear-gradient(#c774e8, #94d0ff);
  }

  .card {
    width: 390px;
    height: 460px;
  }
}

/*# sourceMappingURL=index.bbb265d8.css.map */
