// variables

$pink: #FF6AD5;
$purple: #C774E8;
$light-blue: #94D0FF;
$light-gray: #313F47;
$dull-gray: #C8CCCF;

// reset

html {
  box-sizing: border-box;
  font-size: 16px;
  line-height: 1.25;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body,
h1 {
  margin: 0;
  padding: 0;
  font-weight: normal;
}

img {
  max-width: 100%;
  height: auto;
}

// styles

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  color: $light-gray;
  font-weight: 200;
  text-align: center;
}

.container {
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.card {
  position: relative;
  bottom: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(255,255,255,.95);
  width: 100%;
  height: 100vh;
  overflow-y: hidden;
  padding: 0 20px;
  border-radius: 1%;
}

.avatar {
  display: flex;
  align-items: center;

  img {
    box-sizing: content-box;
    width: 140px;
    height: 140px;
    border-radius: 50%;
    padding: 0 20px;
  }
}

.avatar-line {
  width: 100px;
  border-top: 1px solid $dull-gray;
  flex-grow: 1;
}

.links {
  display: flex;
  align-items: center;
  justify-content: center;
}

.link-icon {
  display: inline-block;
  height: 22px;
  width: 22px;
  border: 2px solid $dull-gray;
  border-radius: 100%;
  padding: 10px;
  margin: 0 10px;
  color: $dull-gray;
  transition: all .1s ease-in;
  box-sizing: unset;

  &:hover {
    border-color: $pink;
    color: $pink;
  }
}

@media only screen and (min-width: 450px) {
  .container {
    background: $purple;
    background: -webkit-linear-gradient(to bottom, $purple, $light-blue);
    background: linear-gradient(to bottom, $purple, $light-blue);
  }

  .card {
    width: 390px;
    height: 460px;
  }
}
